<div class="container">
	<div class="row">
		<div class="filter-block" [formGroup]="filterForm">
			<app-textbox formControlName="filterControl" icon="search"></app-textbox>
			<div class="clear-button" *ngIf="isShowClearButton()" (click)="resetFilter()">
				<app-icon>clear</app-icon>
				<span i18n>Сбросить</span>
			</div>
		</div>
		<div class="closeIcon">
			<app-icon (click)="close()">clear</app-icon>
		</div>
	</div>

	<div *ngIf="list?.length; else noList" class="grid-container" #container>
		<table>
			<thead>
				<tr>
					<th *ngFor="let header of headers"> {{header.name}} </th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of list; let i = index" (click)="select(item)">
					<td class="text-left" *ngFor="let header of headers"> {{item[header.key]}} </td>
				</tr>
			</tbody>
		</table>
	</div>
	<ng-template #noList>
		<div class="main" *ngIf="!filterForm.get('filterControl').value" i18n>Введите значение в строку поиска</div>
		<div class="main" *ngIf="filterForm.get('filterControl').value" i18n>Данные по заданному фильтру не найдены</div>
	</ng-template>
	<app-pending *ngIf="pending" [isBlackTheme]="true" [class.table-pending]="true"></app-pending>
</div>
