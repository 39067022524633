import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { createSelector, select, Store } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import { OverlayService } from "@core/overlay.service";
import { DocType, DocumentState, DocumentType, processingStatusValue } from "@helper/abstraction/documents";
import { Organization } from "@helper/abstraction/organization";
import { documentState } from "@helper/paths";
import { checkDocument, checkDocumentSuccess, saveDocument, checkDocumentError } from "@app/user/user.actions";
import { UserState } from "@app/user/user.reducer";

@Injectable()
export class CheckDocumentsService {
	private organizationInfo?: Organization;
	private unsubscribe$$ = new Subject<void>();

	// сделать запрос на проверку документа
	// если ошибка - вывести ошибку
	// если без ошибки - сохранить в хранилище user документ и сделать навигацию по нему
	// если в хранилище user есть этот документ, то в форме просмотра не делать запрос на получение документа
	// при перезагрузке страницы документа в хранилище не будет, поэтому делается запрос со страницы просмотра/редактирования документа

	constructor(
		private store: Store,
		private actions: Actions,
		private router: Router,
		private overlayService: OverlayService,
	) {
		const section = (appState: any): UserState => appState.user;
		const organizationInfo = createSelector(section, (state: UserState): Organization | undefined => state.organizationInfo);
		this.store.pipe(select(organizationInfo)).pipe(takeUntil(this.unsubscribe$$)).subscribe(org => {
			this.organizationInfo = org;
		});
	}

	public check(document: any, documentType: DocumentType, isThirdPartyAccess = false, isAllowFactoringRole = false, cookieName?: string): void {
		const documentData = {
			processingStatus: document.processingStatus.id || document.processingStatus,
			documentType,
			senderId: document?.senderId || (document?.shipper && document?.shipper?.id) || document?.sender?.id || document?.buyerId || document?.consignor?.id,
			receiverId: document?.receiverId || document?.receiver?.id
		};

		const documentState = this.defineDocumentState(documentData, isThirdPartyAccess);
		if (!documentState) {
			throw "There is no document status in navigation";
		}

		const documentInfo = {
			messageType: document.messageType || document.msgType,
			processingStatus: document.processingStatus.id || document.processingStatus,
			documentId: document.id,
			documentType,
			documentState,
			isAllowFactoringRole,
		};
		const requestParams = this.defineDocumentRequestParams(documentInfo);

		const checkDocumentParams = {
			...requestParams,
			read: document?.read && [documentData.senderId, documentData.receiverId].includes(this.organizationInfo?.id),
			documentForMarkRead: !["TRANSIT"].includes(documentInfo.documentType)
									&& !requestParams.isDraft
									&& !documentInfo.isAllowFactoringRole
		};
		this.store.dispatch(checkDocument(checkDocumentParams));

		this.actions.pipe(
			ofType(checkDocumentSuccess),
			map(resultValue => resultValue.document),
			take(1)
		).subscribe(resDocument => this.saveAndNavigateDoc({ resDocument, document, documentType, documentState, cookieName }));

		this.actions.pipe(
			ofType(checkDocumentError),
			take(1)
		).subscribe(error => {
			if (error.errorResponse.error.errorCode !== "EX1008") {
				this.overlayService.showNotification$(error.errorResponse.error.error || "", "error");
			}
		});
	}

	private saveAndNavigateDoc(doc: {resDocument: any, document: any, documentType: DocumentType, documentState: DocumentState, cookieName?: string}): void {
		this.saveDocumentFromRequestToUserStore(doc.resDocument);
		const data = {
			documentType: doc.documentType,
			documentState: doc.documentState,
			formType: doc.resDocument?.formType,
			processingStatus: this.handleDocumentProcessingStatus(doc.resDocument.processingStatus, doc.document),
			messageType: doc.document.messageType || doc.document.msgType || doc.resDocument.msgType,
			cookieName: doc.cookieName,
			doc: doc.resDocument,
		};
		this.navigateByDocumentType(doc.document, data);
	}

	private handleDocumentProcessingStatus(pS: number, document: any): string {
		let status: string;
		switch (pS) {
			case 98:
				status = "SIGNED_DRAFT";
				break;
			case 99:
				status = "FINALLY_SIGNED_DRAFT";
				break;
			default:
				status = document.processingStatus.id ?? document.processingStatus;
				break;
		}
		// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
		return status!;
	}

	private saveDocumentFromRequestToUserStore(document: any): void {
		this.store.dispatch(saveDocument(document));
	}

	private defineDocumentState(documentData: { processingStatus: any; documentType: DocumentType; senderId: number; receiverId: number }, isThirdPartyAccess = false): DocumentState | undefined {
		const { processingStatus, documentType, senderId, receiverId } = documentData;
		let docState: DocumentState | undefined;
		if (processingStatus && ["DRAFT", "SIGNED_DRAFT", "FINALLY_SIGNED_DRAFT", "SENT"].includes(processingStatus) && !isThirdPartyAccess) {
			docState = "DRAFT";
		}
		if (senderId === this.organizationInfo?.id && processingStatus && processingStatusValue.includes(processingStatus) && !isThirdPartyAccess) {
			docState = "OUTGOING";
			if (documentType !== "ROSEU" && processingStatus === "CREATED_CONFIRMED" && !isThirdPartyAccess)
				docState = "INCOMING";
		}
		if (receiverId === this.organizationInfo?.id && processingStatus && processingStatusValue.includes(processingStatus) && !isThirdPartyAccess) {
			docState = documentType !== "ROSEU" && processingStatus === "CREATED_CONFIRMED" ? "OUTGOING" : "INCOMING";
		}
		if (documentType === "TRANSIT" && processingStatus && processingStatusValue.includes(processingStatus) && !isThirdPartyAccess){
			docState = "OUTGOING";
		}
		if (isThirdPartyAccess) { docState = "OUTGOING"; }
		if (documentType === "ECMR" && ["LOADING", "SHIPPING", "CREATED"].includes(processingStatus)) {
			docState = processingStatus;
		}
		return docState;
	}

	private defineDocumentRequestParams(documentData: {messageType: any; processingStatus: any; documentId: number; documentType: DocumentType; documentState: DocumentState; isAllowFactoringRole: boolean}): any {
		const { messageType, processingStatus, documentId, documentType, documentState, isAllowFactoringRole } = documentData;
		let isResponse;
		const draftTypesList = ["BLRDNR", "BLRWBR", "ORDRSP"];
		switch (documentState) {
			case "DRAFT": {
				if (processingStatus === "DRAFT" && draftTypesList.includes(messageType)) {
					isResponse = true;
				} else if (processingStatus === "FINALLY_SIGNED_DRAFT" && draftTypesList.includes(messageType)) {
					isResponse = true;
				}
				break;
			}
			case "INCOMING": case "OUTGOING": {
				break;
			}
		}
		return { documentId, documentType, isDraft: documentState === "DRAFT", isResponse, isAllowFactoringRole };
	}

	private navigateByDocumentType(document: any, documentData: { documentType: any; documentState: DocumentState; formType: number; processingStatus: any; messageType: any; cookieName?: string, doc?: any }): void {
		const shortData = {
			state: documentData.documentState,
			formType: documentData.formType ?? document.formType,
			id: document.id,
			processingStatus: documentData.processingStatus,
			cookieName: documentData.cookieName,
			version: document?.version ?? documentData?.doc?.version,
			documentType: document?.documentType,
			doc: documentData.doc
		};

		const fullData = {
			...shortData,
			messageType: documentData.messageType
		};
		switch (documentData.documentType) {
			case "ORDERS": this.navigateOrders(fullData);break;
			case "DESADV": this.navigateDesadv(shortData);break;
			case "EWAYBILL": this.navigateEwaybill(fullData); break;
			case "EINVOICE": this.navigateEinvoice(shortData); break;
			case "EINVOICEPMT": this.navigateEinvoicepmt(shortData); break;
			case "TRANSIT": this.navigateTransit(shortData); break;
			case "EDOCUMENT": this.navigateEdocument(fullData, documentData.documentType); break;
			case "BLRDOC": this.navigateBlrdoc(fullData, documentData.documentType); break;
			case "EACTDIF": this.navigateEact(fullData); break;
			case "ECMR": this.navigateEcmr(fullData); break;
			case "ROSEU": this.navigateRoseu(fullData); break;
		}
	}

	private navigateOrders(documentData: { state: DocumentState; id: number; processingStatus: any; messageType: any; cookieName?: string }): void {
		const { state, id, processingStatus, messageType, cookieName } = documentData;
		const queryParams = {
			draftId: id,
			draftType: messageType,
			kind: "220"
		};
		if (!processingStatus) {
			throw "no processing status to navigation";
		}
		if (processingStatus === "DRAFT") {
			if (messageType === "ORDERS")
				this.router.navigate(["user", "documents", "ORDERS", "edit"], { queryParams, state: { cookieName } });
			if (messageType === "ORDRSP")
				this.router.navigate(["user", "documents", "ORDERS", documentState.draft, "response", messageType, "220", id], { state: { cookieName } });
		} else {
			this.router.navigate(["user", "documents", "ORDERS", state?.toLowerCase() || messageType, id], { state: { cookieName } });
		}
	}

	private navigateDesadv(documentData: { state: DocumentState; id: number; processingStatus: any; cookieName?: string }): void {
		const { state, id, processingStatus, cookieName } = documentData;

		if (processingStatus && processingStatus === "DRAFT") {
			this.router.navigate(["user", "documents", "DESADV", "edit", id], { state: { cookieName } });
		} else {
			this.router.navigate(["user", "documents", "DESADV", state?.toLowerCase(), id], { state: { cookieName } });
		}
	}

	private navigateEwaybill(documentData: { state: DocumentState; id: number; formType: number; processingStatus: any; messageType: any; version?: string, cookieName?: string }): void {
		const { state, id, processingStatus, formType, messageType, version, cookieName } = documentData;
		let commands: any[] = [];
		let extras = undefined;
		if (state === "DRAFT") {
			if (processingStatus === "DRAFT") {
				if (messageType && ["BLRDNR", "BLRWBR"].includes(messageType)) {
					commands = ["user", "documents", "EWAYBILL", "draft", "response", messageType, id, version];
					extras = { state: { cookieName, version } };
				} else if (messageType && ["BLRWBL", "BLRDLN"].includes(messageType)) {
					commands = ["user", "documents", "EWAYBILL", "edit"];
					extras = { queryParams: { draftId: id, draftType: messageType, formType, version }, state: { cookieName } };
				}
			} else if (processingStatus === "FINALLY_SIGNED_DRAFT" || processingStatus === "SIGNED_DRAFT") {
				if (messageType && ["BLRDNR", "BLRWBR"].includes(messageType)) {
					commands = ["user", "documents", "EWAYBILL", "response", "sign-draft", messageType, id];
					extras = { queryParams: { version }, state: { cookieName } };
				} else if (messageType && ["BLRWBL", "BLRDLN"].includes(messageType)) {
					commands = ["user", "documents", "EWAYBILL", "sign-draft", messageType, id];
					extras = { queryParams: { version }, state: { cookieName, version } };
				}
			}
		} else {
			commands = ["user", "documents", "EWAYBILL", state.toLowerCase(), messageType, id];
			extras = { queryParams: { version }, state: { cookieName, version } };
		}
		this.router.navigate(commands, extras);
	}

	private navigateEinvoice(documentData: { state: DocumentState; id: number; processingStatus: any; cookieName?: string }): void {
		const { state, id, processingStatus, cookieName } = documentData;
		let commands: any[] = [];
		let extras = undefined;
		if (state === "DRAFT") {
			if (processingStatus === "DRAFT") {
				commands = ["user", "documents", "EINVOICE", "edit"];
				extras = { queryParams: { draftId: id, draftType: "BLRINV" }, state: { cookieName } };
			} else if (["SIGNED_DRAFT", "FINALLY_SIGNED_DRAFT"].includes(processingStatus)) {
				commands = ["user", "documents", "EINVOICE", "sign-draft", id];
				extras = { state: { cookieName } };
			}
		} else {
			commands = ["user", "documents", "EINVOICE", state.toLowerCase(), id];
			extras = { state: { cookieName } };
		}
		this.router.navigate(commands, extras);
	}

	private navigateEinvoicepmt(documentData: { state: DocumentState; id: number; processingStatus: any; cookieName?: string }): void {
		const { state, id, processingStatus, cookieName } = documentData;
		let commands: any[] = [];
		let extras = undefined;
		if (state === "DRAFT") {
			switch (processingStatus) {
				case "DRAFT": {
					commands = ["user", "documents", "EINVOICEPMT", "edit", id];
					extras = { state: { cookieName } };
					break;
				}
				case "SIGNED_DRAFT":
				case "FINALLY_SIGNED_DRAFT": {
					commands = ["user", "documents", "EINVOICEPMT", "signed-draft", id];
					extras = { state: { cookieName } };
					break;
				}
			}
		} else {
			commands = ["user", "documents", "EINVOICEPMT", state.toLowerCase(), id];
			extras = { state: { cookieName } };
		}
		this.router.navigate(commands, extras);
	}

	private navigateTransit(documentData: { state: DocumentState; id: number; processingStatus: any; version?: string; cookieName?: string }): void {
		const { state, id, processingStatus, version, cookieName } = documentData;
		let commands: any[] = [];
		let extras = undefined;
		if (state === "DRAFT") {
			if (processingStatus === "DRAFT") {
				commands = ["user", "documents", "TRANSIT", "edit"];
				extras = { queryParams: { draftId: id, draftType: "TRANSIT", version: version ?? "0" /* TODO remove HARDCODE */ }, state: { cookieName } };
			} else if (["SIGNED_DRAFT", "FINALLY_SIGNED_DRAFT"].includes(processingStatus)) {
				commands = ["user", "documents", "TRANSIT", "sign-draft", id];
				extras = { queryParams: { version }, state: { cookieName } };
			}
		} else {
			commands = ["user", "documents", "TRANSIT", state.toLowerCase(), id];
			extras = { queryParams: { version }, state: { cookieName } };
		}
		this.router.navigate(commands, extras);
	}

	private navigateEdocument(documentData: { state: DocumentState; id: number; processingStatus: any; messageType: any; cookieName?: string }, documentType: DocumentType): void {
		const { state, id, processingStatus, messageType, cookieName } = documentData;
		let commands: any[] = [];
		let extras = undefined;
		if (state === "DRAFT") {
			if (processingStatus === "DRAFT") {
				commands = ["user", "documents", "EDOCUMENT", "edit"];
				extras = { queryParams: { draftId: id, draftType: messageType || "BLRDOC" }, state: { cookieName } };
			} else if (["SIGNED_DRAFT", "FINALLY_SIGNED_DRAFT"].includes(processingStatus)) {
				commands = ["user", "documents", "EDOCUMENT", "sign-draft", id];
				extras = { state: { cookieName } };
			}
		} else {
			commands = ["user", "documents", "EDOCUMENT", state.toLowerCase(), id];
			extras = { state: { cookieName } };
		}
		this.router.navigate(commands, extras);
	}

	private navigateBlrdoc(documentData: { state: DocumentState; id: number; processingStatus: any; messageType: any; cookieName?: string, doc: any }, documentType: DocumentType): void {
		const { id, messageType, cookieName, doc } = documentData;
		const processingStatus = doc.processingStatus.id;
		const state = this.defineDocumentState({ documentType: "BLRDOC", processingStatus: processingStatus, receiverId: doc.receiver.id, senderId: doc.sender.id });
		let commands: any[] = [];
		let extras = undefined;
		if (["SIGNED_DRAFT", "DRAFT"].includes(processingStatus)) {
			commands = ["user", "documents", documentType, "edit"];
			extras = { queryParams: { id, draftType: messageType || "BLRDOC" }, state: { cookieName } };
		} else {
			commands = ["user", "documents", documentType, state?.toLowerCase(), id];
			extras = { state: { cookieName } };
		}
		this.router.navigate(commands, extras);
	}

	private navigateEact(documentData: { state: DocumentState; id: number; processingStatus: any; messageType: any; cookieName?: string }): void {
		const { state, id, processingStatus, messageType, cookieName } = documentData;
		let commands: any[] = [];
		let extras = undefined;
		if (state === "DRAFT") {
			if (processingStatus === "DRAFT") {
				commands = ["user", "documents", "EACTDIF", "edit"];
				extras = { queryParams: { draftId: id, draftType: messageType }, state: { cookieName } };
			} else if (["SIGNED_DRAFT", "FINALLY_SIGNED_DRAFT"].includes(processingStatus)) {
				commands = ["user", "documents", "EACTDIF", "sign-draft", id];
				extras = { state: { cookieName } };
			}
		} else {
			commands = ["user", "documents", "EACTDIF", state.toLowerCase(), id];
			extras = { state: { cookieName } };
		}
		this.router.navigate(commands, extras);
	}

	private navigateEcmr(documentData: { state: DocumentState; id: number; processingStatus: any; messageType: any }): void {
		const { state, id, processingStatus, messageType } = documentData;
		let commands: any[] = [];
		let extras = undefined;
		if (state === "DRAFT") {
			if (processingStatus === "DRAFT") {
				commands = ["user", "documents", "ECMR", "edit"];
			} else if (["SIGNED_DRAFT", "FINALLY_SIGNED_DRAFT"].includes(processingStatus)) {
				commands = ["user", "documents", "ECMR", "view"];
			}
		} else {
			commands = ["user", "documents", "ECMR", 'view'];
		}
		extras = { queryParams: { draftId: id, draftType: messageType, status: processingStatus } };
		this.router.navigate(commands, extras);
	}

	private navigateRoseu(documentData: { state: DocumentState; id: number; processingStatus: any; messageType: any; cookieName?: string, documentType?: DocType }): void {
		const { state, id, processingStatus, messageType, cookieName, documentType } = documentData;
		const docType: string = documentData?.documentType?.id!;
		let commands: any[] = [];
		let extras = undefined;
		if (state === "DRAFT") {
			if (processingStatus === "DRAFT") {
				commands = ["user", "documents", "ROSEU", "edit"];
				extras = { queryParams: { draftId: id, draftType: messageType, documentType: docType }, state: { cookieName } };
			} else if (["SIGNED_DRAFT", "FINALLY_SIGNED_DRAFT"].includes(processingStatus)) {
				commands = ["user", "documents", "ROSEU", "sign-draft", id];
				extras = { state: { cookieName } };
			}
		} else {
			commands = ["user", "documents", "ROSEU", state.toLowerCase(), id];
			extras = { state: { cookieName, document: documentData } };
		}

		this.router.navigate(commands, extras);
	}
}
