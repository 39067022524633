<ng-container *ngIf="data?.length; else emptyTable">
  <div class="data-block" [class.gtin-mode]="gtinMode && documentType === 'EWAYBILL'" [class.other-doc]="documentType !== 'EWAYBILL' && !tabMode">
    <p-table
      [value]="data"
      [scrollable]="true"
      scrollHeight="flex"
      selectionMode="single"
      (onRowSelect)="onRowSelect($event)">
        <ng-template pTemplate="header">
            <tr>
              <th>GTIN</th>
              <th>Наименование</th>
              <th>Код ТНВЭД</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item">
              <td>{{ item.gtin }}</td>
              <td>{{ item.name }}</td>
              <td [class.empty-item]="!item.tnvedCode">{{ item.tnvedCode || "-" }}</td>
            </tr>
        </ng-template>
      <ng-template *ngIf="data?.length > 1" pTemplate="summary">
          <div class="flex align-items-center justify-content-between">
            Уточните текст поиска.
          </div>
      </ng-template>
    </p-table>
  </div>
</ng-container>
<ng-template #emptyTable>
  <div class="empty-table" [class.gtin-mode]="documentType === 'EWAYBILL' || tabMode">
    <span>
      <ng-container *ngIf="!notFound; else notFoundData">
        <ng-container *ngIf="!gtinMode">Для поиска введите GTIN или наименование</ng-container>
        <ng-container *ngIf="gtinMode && documentType === 'EWAYBILL'">Для добавления введите GTIN (о.ц.) и наименование</ng-container>
      </ng-container>
      <ng-template #notFoundData>
        Данные по заданному фильтру не найдены
      </ng-template>
    </span>
  </div>
</ng-template>
