import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserCookieService } from "@app/user/user-core/services/user-cookie.service";
import { of, Subscription } from "rxjs";
import { switchMap, delay } from "rxjs/operators";

@Component({
	selector: "app-goods-filter",
	templateUrl: "./goods-filter.component.html",
	styleUrls: ["./goods-filter.component.scss"]
})
export class GoodsFilterComponent implements OnDestroy {
	@Output() public filterChanges: EventEmitter<any> = new EventEmitter<any>();
	@Input() public isEwaybillGoodsFilter = false;
	@Input() public gtin: string | null = null;
	public form: FormGroup;
	private subscription: Subscription;
	constructor(
		private formBuilder: FormBuilder,
		private readonly userCookieService: UserCookieService,
	) {
		this.userCookieService.setCookieName("goodsFilter");
		const initValue = this.userCookieService.isCookieFilterValueExist() ? this.userCookieService.getCookieFilterValue() : undefined;
		this.form = initValue
			? this.initForm(initValue)
			: this.initForm();

		this.subscription = this.form.valueChanges.pipe(
			switchMap(el => of(el).pipe(delay(500)))
		).subscribe(form => {
			this.filterChanges.emit(form);
		});
	}

	public ngOnInit(): void {
		if (this.isEwaybillGoodsFilter) {
			this.form.patchValue({
				name: this.gtin
			});
		}

	}

	public initForm(filter?: any): FormGroup {
		return this.formBuilder.group({
			gtin: [filter?.gtin || null],
			name: [filter ? filter?.name : null],
			page: 1,
			size: 50
		});
	}

	public clear(): void {
		this.form.setValue(this.initForm().value);
	}

	public ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}
}
