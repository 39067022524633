import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

import { TradeItemDto } from "@app/user/marks/marks-store/marks-types";
import { UserBackendService } from "@app/user/user-core/services/user-backend.service";
import { SearchItemPartyParams } from "@helper/abstraction/filter";
import { DocumentType } from "@helper/abstraction/documents";
import { environment } from "src/environments/environment";

const TabItems: [string, string][] = [
	["База EPASS", "0"],
	["Каталог товаров", "1"],
	["Ввод GTIN ограниченной циркуляции", "2"]
];

@Component({
	selector: "app-search-items-popup",
	templateUrl: "./search-items-popup.component.html",
	styleUrls: ["./search-items-popup.component.scss"]
})
export class SearchItemsPopupComponent implements OnInit {
	public gtinMode = false;
	public tabMode = false;
	public documentType?: DocumentType;
	public itemsSelectBoxOption = {
		getData$: (selectBoxState: SearchItemPartyParams): Observable<TradeItemDto[]> => {
			const params: SearchItemPartyParams = {
				...selectBoxState,
				tabMode: this.tabMode
			};
			return this.userBackendService.epass.itemToPartySearchItem.post$(params);
		},
		mapData: (data: TradeItemDto[]): TradeItemDto[] => data,
	};

	public tabs = TabItems;
	private env = environment;

	constructor(
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef,
		private userBackendService: UserBackendService,
	) {
		this.tabMode = this.env.project === "BM";
	}

	public ngOnInit(): void {
		this.setData();
	}

	public setData(): void {
		this.config.width = "820px";
		this.config.header = "Поиск товара";
		for (let k in this.config.data) {
			(this as any)[k] = this.config.data[k];
		}
		this.config.height = "630px";
		this.tabs = this.checkTabs();
	}

	public close(): void {
		this.ref.close();
	}

	public addItem(item: TradeItemDto): void {
		this.ref.close(item);
	}

	private checkTabs(): [string, string][] {
		if (this.tabMode && this.documentType !== "EWAYBILL") {
			const tabValues = this.tabs.filter(el => el[1] !== "2");
			return tabValues;
		}
		return this.tabs;
	}
}
