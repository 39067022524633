<section>
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="search()">
    <div class="filed-column">
      <ng-container *ngIf="documentType === 'EWAYBILL' || documentType !== 'EWAYBILL' && env.project === 'BM'">
        <div class="field-block switch-block">
          <ng-container *ngIf="!tabMode">
            <label>Ввести GTIN ограниченной циркуляции</label>
            <p-inputSwitch formControlName="gtinMode"></p-inputSwitch>
          </ng-container>
          <ng-container *ngIf="tabMode">
            <app-tab [tabs]="tabs" formControlName="tab"></app-tab>
          </ng-container>
        </div>
      </ng-container>
      <div class="field-raw">
        <div class="field-block">
          <label [class.required]="checkMode() && documentType === 'EWAYBILL'">GTIN</label>
          <app-numberbox
            formControlName="gtin"
            placeholder="{{ checkMode() ? 'Введите GTIN (о.ц.)' : 'Поиск по GTIN' }}"
            maxLength="14"
            name="GTIN">
          </app-numberbox>
        </div>
        <div class="field-block">
          <label [class.required]="checkMode() && documentType === 'EWAYBILL'">Наименование</label>
          <app-textbox
            formControlName="name"
            placeholder="{{ checkMode() ? 'Введите наименование' : 'Поиск по наименованию (не менее 4 символов)' }}"
            maxLength="512"
            name="Наименование">
          </app-textbox>
        </div>
        <button [disabled]="!checkForm()" type="button" appSkin="simple-gray no-shadow bold" (click)="clearForm(gtinMode)">Сбросить</button>
        <button [disabled]="isLoading" type="submit" appSkin="simple-gray no-shadow bold">{{ checkMode() ? "Добавить" : "Поиск" }}</button>
      </div>
    </div>
  </form>
  <app-search-items-list
    [data]="data"
    [notFound]="notfound"
    (appSelect)="select($event)"
    [gtinMode]="gtinMode"
    [tabMode]="tabMode"
    [documentType]="documentType">
  </app-search-items-list>
</section>
