import { Observable, Subject, of } from "rxjs";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { ConfigurationService } from "@core/configuration.service";
import { DocumentKind, DocumentsDto, DocumentsParams, DocumentType, MassReport, MessageType, DocumentState, MultipleSendingDto, StatusDto } from "@helper/abstraction/documents";
import { DraftDto, DraftType } from "@helper/abstraction/draft";
import { UserBackendService } from "../services/user-backend.service";
import { UserPermissionService } from "../services/user-permission.service";
import { Organization } from "@helper/abstraction/organization";
import { UserState } from "@app/user/user.reducer";
import { Store, createSelector, select } from "@ngrx/store";
import { DocumentsState } from "@app/user/documents/documents-store/documents.reducer";
import { takeUntil } from "rxjs/operators";

export class Documents {
	public unsubscribe$$ = new Subject<void>();
	private organizationInfo?: Organization;

	public list = {
		get$: (dp: DocumentsParams): Observable<DocumentsDto> => {
			return this.userBackendService.getController(dp.documentTypeId).list.get$(dp, this.organizationInfo?.id);
		}
	};

	public statisticTypes = {
		list: {
			get$: (): Observable<any> => {
				const url = `${this.config.api.root}/documents/statistic/types/list`;
				return this.http.get<any[]>(url, { withCredentials: true });
			}
		}
	};

	public processingStatuses = {
		list: {
			get$: (documentTypeId: DocumentType, documentState?: DocumentState): Observable<any> => {
				let url: string;
				if (documentState)
					url = `${this.config.api.root}/documents/${documentTypeId}/${documentState}/processing-statuses/list`;
				else
					url = `${this.config.api.root}/documents/${documentTypeId}/processing-statuses/list`;
					// TODO
				return documentTypeId === "ECMR" ? of([]) : this.http.get<any[]>(url, { withCredentials: true });
			}
		},

		statisticList: {
			get$: (documentTypeId?: DocumentType): Observable<any> => {
				const docType = documentTypeId === "THIRDPARTYACCESS" ? "ewaybillThirdPartyAccessStatus" : "processing-statuses";
				const url = `${this.config.api.root}/documents/statistic/${docType}/list`;
				return this.http.get<any[]>(url, { withCredentials: true });
			}
		}
	};


	public createWithValidation = {
		post$: (draftType: DraftType, msgEwaybillDraftDto: DocumentKind): Observable<DraftDto> => {
			const type = this.userPermissionService.getDocumentType(draftType);
			if (!type)
				throw Error("Can't define type by pass draftType");
			return this.userBackendService.getController(type).createWithValidation.post$(msgEwaybillDraftDto);
		}
	};

	public saveSignedCancel = {
		post$: (messageType: MessageType, id: string, signature: number[], xmlBody: string): Observable<number> => {
			const type = this.userPermissionService.getDocumentType(messageType);
			if (!type)
				throw Error("Can't define type by pass draftType");
			return this.userBackendService.getController(type).saveSignedCancel.post$(id, signature, xmlBody);
		}
	};

	public massSendDocuments = {
		post$: (documentType: DocumentType, ids: MultipleSendingDto, isVersion = false): Observable<MassReport> => {
			if (!documentType)
				throw Error("Can't define type by pass documentType");
			return this.userBackendService.getController(documentType).sendMass.post$(documentType, ids, isVersion);
		}
	};

	public markAsRead = {
		post$: (idsList: number[], docType: DocumentType): Observable<any> => {
			const url = `${this.config.api.root}/${docType}/markAsRead`;
			return this.http.post<any>(url, idsList, { withCredentials: true });
		}
	};

	public massDeleteDocuments = {
		delete$: (documentType: DocumentType, ids: string[], isVersion = false): Observable<MassReport> => {
			if (!documentType)
				throw Error("Can't define type by pass documentType");
			return this.userBackendService.getController(documentType).deleteMass.delete$(documentType, ids, isVersion);
		}
	};

	public saveSigned = {
		post$: (draftType: DraftType, documentDto: { id: string; xmlBody: string }): Observable<any> => {
			const type = this.userPermissionService.getDocumentType(draftType);
			if (!type)
				throw Error("Can't define type by pass draftType");
			return this.userBackendService.getController(type).saveSigned.post$(draftType, documentDto);
		}
	};

	public downloadArchive = {
		post$: (documentIds: number[], documentType: DraftType, reportFormatType: string, isStatisticValue?: boolean, statisticDocType?: string): Observable<HttpResponse<Blob>> => {
			const type = this.userPermissionService.getDocumentType(documentType);
			if (!type)
				throw Error("Can't define type by pass draftType");
			return this.userBackendService.getController(type).downloadArchive.post$(documentIds, reportFormatType, isStatisticValue, statisticDocType);
		}
	};

	public getDocument = {
		getCleanDraftOfTheDocument$: (documentProperties: { documentId: number; documentType: DocumentType }): Observable<any> => {
			const url = `${this.config.api.root}/${documentProperties.documentType}/${documentProperties.documentId}`;
			return this.http.get<any>(url, { withCredentials: true });
		}
	};

	public getDocumentDraft = {
		getDraftOfTheDocument$: (documentProperties: { documentId: number; documentType: DocumentType; isResponse?: boolean }): Observable<any> => {
			let url = `${this.config.api.root}/${documentProperties.documentType}/draft`;
			url += documentProperties.isResponse ? `/response/${documentProperties.documentId}`: `/${documentProperties.documentId}`;
			return this.http.get<any>(url, { withCredentials: true });
		}
	};

	public checkDocument = {
		get$: (documentProperties: { documentId: number; documentType: DocumentType; isDraft: boolean; isResponse?: boolean }): Observable<any> => {

			let url = `${this.config.api.root}/${documentProperties.documentType}`;
			url += !documentProperties.isDraft
				? `/${documentProperties.documentId}`
				: documentProperties.isResponse
					? `/draft/response/${documentProperties.documentId}`
					: `/draft/${documentProperties.documentId}`;
			
			if (documentProperties.documentType === "ROSEU") {
				url = url.replace("draft/", "");
			}
			// TODO refactoring later
			if (documentProperties.documentType === "BLRDOC") {
				url = url.replace("draft/", "");
			}
			return this.http.get<any>(url, { withCredentials: true });
		}
	};

	public status = {
		get$: (id: number, documentType: DocumentType): Observable<StatusDto> => {
			let url = `${this.config.api.root}/${documentType}/${id}/status`;
			return this.http.get<StatusDto>(url, { withCredentials: true });
		}
	};

	constructor(
		private store: Store,
		private config: ConfigurationService,
		private http: HttpClient,
		private userBackendService: UserBackendService,
		private userPermissionService: UserPermissionService,
	) {
		const section = (appState: any): UserState => appState.user;
		const organizationInfo = createSelector(section, (state: UserState): Organization | undefined => state?.organizationInfo);
		this.store.pipe(select(organizationInfo)).pipe(takeUntil(this.unsubscribe$$)).subscribe(org => {
			this.organizationInfo = org;
		});
	}
}
