import { Injectable } from "@angular/core";
import { DescriptionDocumentType, DocumentType, MessageType } from "@helper/abstraction/documents";
import { Observable, Subject, ReplaySubject, forkJoin, BehaviorSubject, of, combineLatest } from "rxjs";
import { takeUntil, take, map, switchMap } from "rxjs/operators";
import { Store, createSelector, select } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";

import { DraftType } from "@helper/abstraction/draft";
import { Role } from "@helper/abstraction/roles";
import { DocumentsState } from "@app/user/documents/documents-store/documents.reducer";
import { UserState } from "@app/user/user.reducer";
import { resetStore } from "@app/app.reducer";
import { LocationProvider } from "@helper/abstraction/providers";

enum PermissionModules {
	USER_MANAGEMENT = "USER_MANAGEMENT"
}

@Injectable()
export class UserPermissionService {
	public offsetHeight$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public readonly permissionModules = PermissionModules;
	public readonly documentTypeToDraftMap: { [key in DocumentType]: DraftType[] } = {
		EWAYBILL: ["BLRWBL", "BLRDLN", "BLRDNR", "BLRWBR"],
		ORDERS: ["ORDERS", "ORDRSP"],
		DESADV: ["DESADV"],
		ORDRSP: [],
		EINVOICE: ["BLRINV"],
		EINVOICEPMT: ["BLRPMT"],
		TRANSIT: ["BLRSPT"],
		EDOCUMENT: ["BLRDOC"],
		BLRDOC: ["BLRDOC"],
		EACTDIF: ["BLRADF"],
		ECMR: ["ECMR"],
		ROSEU: ["REUMSG", "REUINF", "UPD"],
		ROAMING: [],
		THIRDPARTYACCESS: []
	};

	public roles$: Observable<Role[]>;
	public permissions$: Observable<string[] | undefined>;
	public unsubscribe$$ = new Subject<void>();
	private draftToDocumentTypeMap$$ = new ReplaySubject<Map<DraftType, DescriptionDocumentType>>(1);
	private readonly documentTypesPriority: { [key in DocumentType]: number } = {
		"ORDERS": 1,
		"DESADV": 2,
		"EWAYBILL": 3,
		"ORDRSP": 4,
		"EINVOICE": 5,
		"EINVOICEPMT": 6,
		"TRANSIT": 7,
		"EDOCUMENT": 8,
		"BLRDOC": 9,
		"EACTDIF": 10,
		"ECMR": 11,
		"ROSEU": 12,
		"ROAMING": 13,
		"THIRDPARTYACCESS": 14
	};

	private readonly documentResponseTypeToCommonType: { [key: string]: DraftType } = {
		BLRDNR: "BLRDLN",
		BLRWBR: "BLRWBL"
	};

	constructor(
		private readonly userStore: Store<DocumentsState>,
		private readonly actions: Actions
	) {
		const selectUser = (appState: any): UserState => appState.user;
		const selectRoles = createSelector(selectUser, (state: UserState): Role[] => state.roles);
		const selectPermissions = createSelector(selectUser, (state: UserState): string[] | undefined => state.permissions);
		this.roles$ = this.userStore.pipe(select(selectRoles), takeUntil(this.unsubscribe$$));
		this.permissions$ = this.userStore.pipe(select(selectPermissions), takeUntil(this.unsubscribe$$));

		this.actions.pipe(
			ofType(resetStore),
			takeUntil(this.unsubscribe$$)
		).subscribe(() => this.offsetHeight$$.next(0));
	}

	public getDescriptionDocumentType$(docType: MessageType | string): Observable<DescriptionDocumentType | undefined> {
		return this.draftToDocumentTypeMap$$.pipe(take(1), takeUntil(this.unsubscribe$$), map(map => map.get(docType as MessageType)));
	}

	public initDocumentTypes(dts: DescriptionDocumentType[]): void {
		const docToDraft = this.documentTypeToDraftMap;
		this.draftToDocumentTypeMap$$.next(Object.keys(docToDraft).reduce((map, key) => {
			const docType = dts.find(e => e.id === key);
			return docType ? docToDraft[key as DocumentType].reduce((m, draftType) => {

				/** TODO new edocument ??? */
				if (docType.id === "BLRDOC" && draftType === "BLRDOC") {
					draftType = "BLRDOCNEW"
				}
				/** TODO new edocument ??? */

				m.set(draftType, docType);
				return m;
			}, map) : map;
		}, new Map<DraftType, DescriptionDocumentType>()));
	}

	public getDocumentTypes$(...roles: Role[]): Observable<DescriptionDocumentType[]> {
		return this.draftToDocumentTypeMap$$.pipe(
			map(draftToDocumentType => {
				const dts = [...roles.reduce((dts, role) => {
					const draftType = this.roleToDraftType(role);
					const docType = draftToDocumentType.get(draftType);
					if (docType) {
						dts.add(docType);
					}
					return dts;
				}, new Set<DescriptionDocumentType>())];

				/** TODO new edocument ??? */
				const edoc = dts.find(el => el.id === "EDOCUMENT");
				if (edoc && this.providerLocation() === "DEV") {
					dts.push(draftToDocumentType.get("BLRDOCNEW")!);
				}
				/** TODO new edocument ??? */
				
				return dts.sort(this.sortDocumentTypes.bind(this));
			})
		);
	}

	public getDraftTypes(documentTypeId: string | DocumentType): DraftType[] {
		return (this.documentTypeToDraftMap[documentTypeId as DocumentType] || []).slice(0);
	}

	public getDocumentType(draftType: string | DraftType): DocumentType | undefined {
		const map = this.documentTypeToDraftMap;
		for (const docType in map) {
			const draftTypes = map[docType as DocumentType] || [];
			if (draftTypes.some(dt => dt === draftType))
				return docType as DocumentType;
		}
	}

	public getCommonDraftType(draftType: string | DraftType): DraftType {
		return this.documentResponseTypeToCommonType[draftType as DraftType];
	}

	public checkPermission$(...roleIds: string[]): Observable<boolean> {
		return this.roles$.pipe(
			take(1),
			map(roles => roles.some(r => roleIds.some(ri => ri === r.id)))
		);
	}

	public hasPermissionAccess(acceptModule: string | null): Observable<boolean> {
		if (!acceptModule)
			return of(true);
		return this.permissions$.pipe(switchMap(e => of((e || []).indexOf(acceptModule) !== -1)), take(1));
	}

	public filterPermissionTabs(tabs: string[][], permissions: (string | null)[]): Observable<string[][]> {
		this.hasPermissionAccess = this.hasPermissionAccess.bind(this);
		return combineLatest([
			of(tabs),
			of(permissions),
			of(this.hasPermissionAccess)
		]).pipe(
			map(([tabs, permissions, checkFn]) => [tabs, tabs.map((tab, index) => checkFn(permissions[index]))]),
			switchMap(([tabs, permissionsObs]) => forkJoin([of(tabs), combineLatest(permissionsObs)])),
			map(([tabs, permissions]) => (tabs as string[][]).filter((e, index) => permissions[index])),
		);
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	private sortDocumentTypes(first: DescriptionDocumentType, second: DescriptionDocumentType): number {
		if (this.documentTypesPriority[first.id] > this.documentTypesPriority[second.id]) {
			return 1;
		}
		return -1;
	}

	private roleToDraftType(role: Role): DraftType {
		return role.id.split("_")[0] as DraftType;
	}

	/**
	 * 
	 * TODO this expression contour for new doc hidden
	 */
	private providerLocation(): LocationProvider {
		let locationContour: LocationProvider;
		switch (window.location.host) {
			// case "edi-pub.ctt.by":
			// case "edi-pub.bidmart.by":
			// case "a1.edi.meta-era.by":
			// 	locationContour = "PUB";
			// 	break;
			case "edi.ctt.by":
			case "edi.bidmart.by":
			case "edi.meta-era.by":
				locationContour = "PROD";
				break;
			default:
				locationContour = "DEV";
				break;
		}
		return locationContour;
	}
}
