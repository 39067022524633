import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfigurationService } from "@core/configuration.service";
import { Observable } from "rxjs";
import { DraftDto, SignedDraftDto, SignedDraftSCCrypto } from "@helper/abstraction/draft";
import { ProxyPopupForm } from "@shared/proxy-popup/proxy-confirm-popup/proxy-confirm-popup.component";

export class Signing {
	constructor(private config: ConfigurationService, private http: HttpClient) { }

	public post$(xml: string, time?: Date): Observable<SignedDraftDto> {
		const timestamp = time && Number.isFinite(+time) ? +time : +(new Date());
		const url = `${this.config.signingServiceNew}/signXml`;
		const body = new URLSearchParams();
		body.set("timestamp", timestamp.toString());
		body.set("xml", xml);

		const options = {
			headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8")
		};

		return this.http.post<SignedDraftDto>(url, body.toString(), options);
	}

	public postSCCrypto$(draftDto: DraftDto, form: ProxyPopupForm): Observable<SignedDraftSCCrypto> {
		const url = `${this.config.signingServiceNew}/SCCrypto/ra/signd`;
		let dataWithUNP;
		const data = {
			Auth: {
				CryptoType: 1,
				KeyID: draftDto.publicId,
				Password: form.password
			},
			Data: draftDto.xmlByteDate,
			DataB64: draftDto.xmlBase64,
			OptAddAllCert: false,
			OptAddCert: true
		};
		if (form.unp)
			dataWithUNP = {
				...data,
				Attributes: [{
					Data: draftDto.xmlByteDate,
					OID: "1.2.112.1.2.1.1.1.1.2",
					Text: form.unp
				}]
			};
		return this.http.post<SignedDraftSCCrypto>(url, dataWithUNP || data);
	}

	public getCurrentVersionSCCrypto$(): Observable<string> {
		const url = `${this.config.signingServiceNew}/SCCrypto/ra/version`;
		return this.http.get<string>(url);
	}
}
