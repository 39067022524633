import { Component, Input } from "@angular/core";

@Component({
	selector: "app-shared-icons",
	templateUrl: "./shared-icons-component.html",
	styleUrls: ["./shared-icons-component.scss"],
})
export class SharedIconsComponent {
	@Input() public isSender?: boolean;
	@Input() public isLoading = false;
	@Input() public isRead?: boolean;
	@Input() public isReadByPartner?: boolean;
	@Input() public titleText?: string;
	@Input() public iconName?: string;
	@Input() public errorMessage?: string;
	@Input() public isNewVersionDoc?: boolean;
	@Input() public isDocumentGrid?: boolean;
}
