import { Component, EventEmitter, Input, Output } from "@angular/core";

import { TradeItemDto } from "@app/user/marks/marks-store/marks-types";
import { DocumentType } from "@helper/abstraction/documents";

@Component({
  selector: "app-search-items-list",
  templateUrl: "./search-items-list.component.html",
  styleUrls: ["./search-items-list.component.scss"]
})
export class SearchItemsListComponent {
	@Input() public data: TradeItemDto[] = [];
	@Input() public notFound = false;
	@Input() public gtinMode = false;
	@Input() public tabMode = false;
	@Input() public documentType?: DocumentType;
	@Output() public appSelect: EventEmitter<TradeItemDto> = new EventEmitter<TradeItemDto>();

  public onRowSelect(event: any): void {
    this.appSelect.emit(event.data as TradeItemDto);
  }
}
