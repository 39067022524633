import { DocumentsParams, DocumentsDto, DocumentKind, DocumentBody, MassReport, DocumentType, MultipleSendingDto } from "@helper/abstraction/documents";
import { Observable, of } from "rxjs";
import { HttpClient, HttpResponse, HttpParams } from "@angular/common/http";
import { Controller } from "./controller";
import { DraftDto, DraftType } from "@helper/abstraction/draft";
import { Directive } from "@angular/core";
import { map } from "rxjs/operators";

@Directive()
export abstract class DocumentController<T extends DocumentKind> extends Controller {
	public cancel = {
		get$: (documentId: string, base64?: boolean): Observable<DraftDto> => {
			const url = `${this.apiUrl}/${documentId}/cancel`;
			let params = new HttpParams();
			if (typeof base64 === "boolean") {
				params = params.set("base64", base64!.toString());
			}
			return this.http.get<DraftDto>(url, { withCredentials: true, params });
		}
	};

	public list = {
		get$: (dp: DocumentsParams, orgId?: number): Observable<DocumentsDto> => {
			let url: string;
			if (!["ROSEU"].includes(dp.documentTypeId)){
				url = `${this.apiUrl}/${dp.documentState.toUpperCase()}/filteredPage`;
			} else {
				url = `${this.apiUrl}/filteredPage`;
			}
			const db: DocumentBody = {
				documentNumber: dp.documentNumber,
				edocumentNumber: dp.edocumentNumber,
				documentDateStart: dp.documentDateStart,
				documentDateEnd: dp.documentDateEnd,
				partnerId: dp.partnerId,
				storageId: dp.storageId,
				processingStatusList: dp.processingStatusList,
				documentType: dp.documentType?.id,
				formType: dp.formType?.id,
				documentDirection: dp.documentDirection?.id,
				useDocumentDate: dp.useDocumentDate ? Boolean(Number(dp.useDocumentDate)) : undefined,
				typeCode: dp.edocumentType?.code,
				fileName: dp.fileName,
				// documentState: ["BLRDOC"].includes(dp.documentTypeId) ? dp.documentState : undefined,
			};
			for (const key in db) {
				if (db[key as keyof DocumentBody] === null) {
					delete db[key as keyof DocumentBody];
				}
			}

			let params = new HttpParams();
			params = params.set("page", dp.page.toString());
			params = params.set("size", dp.size.toString());
			return this.http.post<DocumentsDto>(url, db, { withCredentials: true, params });
		}
	};

	public createWithValidation = {
		post$: (msgEwaybillDraftDto: DocumentKind): Observable<DraftDto> => {
			const url = `${this.apiUrl}/draft/createWithValidation`;
			return this.http.post<DraftDto>(url, msgEwaybillDraftDto, { withCredentials: true });
		}
	};

	public saveSignedCancel = {
		post$: (id: string, signature: number[], xmlBody: string): Observable<number> => {
			const url = `${this.apiUrl}/draft/saveSignedCancel`;
			return this.http.post<number>(url, { id, signature, xmlBody }, { withCredentials: true });
		}
	};

	public saveSigned = {
		post$: (draftType: DraftType, documentDto: { id: string; xmlBody: string }): Observable<any> => {
			const url = `${this.apiUrl}/draft/saveSigned`;
			return this.http.post(url, documentDto, { withCredentials: true });
		}
	};

	public downloadArchive = {
		post$: (documentIds: number[], reportFormatType: string, isStatisticValue = false, statisticDocType?: string): Observable<HttpResponse<Blob>> => {
			let url;
			if (statisticDocType)
				url = `${this.apiUrl}/downloadArchive/xml?documentType=${statisticDocType}`;
			else
				url = `${this.apiUrl}/downloadArchive/${reportFormatType}?isStatisticValue=${isStatisticValue}`;
			return this.http.post<Blob>(url, documentIds, {
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public sendMass = {
		// like abstract method, need to getController
		post$: (documentType: DocumentType, ids: MultipleSendingDto, isVersion = false): Observable<MassReport> => {
			return of({ documentType, ids } as unknown as MassReport);
		}
	};

	public deleteMass = {
		// like abstract method, need to getController
		delete$: (documentType: DocumentType, ids: string[], isVersion = false): Observable<MassReport> => {
			return of({ documentType, ids } as unknown as MassReport);
		}
	};

	constructor(protected http: HttpClient) { super(); }

	public get$(documentId: number): Observable<T> {
		const url = `${this.apiUrl}/${documentId}`;
		return this.http.get<T>(url, { withCredentials: true });
	}

	public getEwaybillMsgType$(documentId: number, isDraft: boolean): Observable<string | undefined> {
		const draftPathPart = isDraft ? "draft/" : "";
		const url = `${this.apiUrl}/${draftPathPart}${documentId}`;
		return this.http.get<any>(url, { withCredentials: true }).pipe(map(data => data?.msgType));
	}

	public getDraft$(documentId: number, edocumentType?: DocumentType): Observable<T> {
		let url = `${this.apiUrl}/draft/${documentId}`;
		if (["BLRDOC"].includes(edocumentType!)) {
			url = url.replace("draft/", "");
		}
		return this.http.get<T>(url, { withCredentials: true });
	}
}
