import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { PartnersParams, PartnersDto, ForeignPartnerDto } from "@helper/abstraction/partners";
import { Observable } from "rxjs";
import { StoragesParams, StoragesDto, TypedStoragesParams, Storage, StorageCreateDto } from "@helper/abstraction/storages";
import { ConsigneesParams, ConsigneesDto } from "@helper/abstraction/consignee";
import { UnloadingPointsParams, UnloadingPointsDto } from "@helper/abstraction/unload-points";
import { LoadingPointsParams, LoadingPointsDto } from "@helper/abstraction/loading-points";
import { Organization as IOrganization, OrganizationDto, PartyCheckDto, PublicContractsList } from "@helper/abstraction/organization";
import { map } from "rxjs/operators";
import { FilterRequestParams, PartnersFilterDto } from "@helper/abstraction/filter";
import { isEveryFieldNull } from "@helper/methods";
import { PartyDto } from "@helper/abstraction/mns-ewaybill";
import { ArchiveTariffPlanDto, TariffFunctionDto, TariffPackageDto, TariffPlanDto } from "@helper/abstraction/tariff";

export class Organization {
	public partners = {
		list: {
			post$: (pp: FilterRequestParams): Observable<PartnersDto> => {
				const url = `${this.config.api.root}/organization/partners/list`;
				let params = new HttpParams();
				if (pp.page) {
					params = params.set("page", pp.page.toString());
				}
				if (pp.size) {
					params = params.set("size", pp.size.toString());
				}
				let value: PartnersFilterDto = {
					name: pp.search || pp.name,
					gln: pp?.gln,
					unp: pp?.unp,
				};
				if (pp.connectorGln) {
					value = { ...value, connectorGln: pp.connectorGln };
				}
				if (pp.status) {
					value = { ...value, status: pp.status };
				}
				return this.http.post<PartnersDto>(url, !isEveryFieldNull(value) ? value : {}, { params, withCredentials: true });
			}
		},

		foreign: {
			foreignAdd$: (foreign: ForeignPartnerDto): Observable<ForeignPartnerDto> => {
				const url = `${this.config.api.root}/organization/addForeignPartner`;
				return this.http.post<ForeignPartnerDto>(url, foreign, { withCredentials: true });
			},
			foreignGet$: (id: number): Observable<ForeignPartnerDto> => {
				const url = `${this.config.api.root}/organization/getForeignPartner/${id}`;
				return this.http.get<ForeignPartnerDto>(url, { withCredentials: true });
			},
			foreignEdit$: (foreign: ForeignPartnerDto): Observable<ForeignPartnerDto> => {
				const url = `${this.config.api.root}/organization/editForeignPartner`;
				return this.http.post<ForeignPartnerDto>(url, foreign, { withCredentials: true });
			},
		}
	};

	public suppliers = {
		list: {
			get$: (sup: ConsigneesParams): Observable<ConsigneesDto> => {
				const url = `${this.config.api.root}/organization/suppliers/list`;
				let params = new HttpParams();
				params = params.set("documentTypeId", sup.documentTypeId);
				params = params.set("page", sup.page.toString());
				params = params.set("size", sup.size.toString());

				if (sup.searchText) {
					params = params.set("supplierName", sup.searchText);
				}

				return this.http.get<ConsigneesDto>(url, { params, withCredentials: true });
			}
		}
	};

	public buyers = {
		list: {
			get$: (pp: PartnersParams): Observable<PartnersDto> => {
				const url = `${this.config.api.root}/organization/buyers/list`;
				let params = new HttpParams();
				params = params.set("page", pp.page.toString());
				params = params.set("size", pp.size.toString());
				if (pp.documentTypeId)
					params = params.set("documentTypeId", pp.documentTypeId);
				if (pp.searchText)
					params = params.set("buyerName", pp.searchText);
				return this.http.get<PartnersDto>(url, { params, withCredentials: true });
			}
		}
	};

	public storages = {
		list: {
			get$: (sp: StoragesParams): Observable<StoragesDto> => {
				const url = `${this.config.api.root}/organization/storages/list`;
				let params = new HttpParams();
				params = params.set("page", sp.page.toString());
				params = params.set("size", sp.size.toString());
				if (sp.searchText)
					params = params.set("storageName", sp.searchText);
				return this.http.get<StoragesDto>(url, { params, withCredentials: true });
			},
			getByStorageType$: (sp: TypedStoragesParams): Observable<StoragesDto> => {
				const url = `${this.config.api.root}/organization/storages/${sp.storageTypeId}/list`;
				let params = new HttpParams();
				params = params.set("page", sp.page.toString());
				params = params.set("size", sp.size.toString());

				if (sp.documentTypeId) {
					params = params.set("documentTypeId", sp.documentTypeId);
				}
				if (sp.search) {
					params = params.set("storageName", sp.search);
				}
				if (sp.partnerId) {
					params = params.set("partnerId", sp.partnerId.toString());
				}
				return this.http.get<StoragesDto>(url, { params, withCredentials: true });
			},
			loadingPoints$: (lpp: LoadingPointsParams): Observable<LoadingPointsDto> => {
				const url = `${this.config.api.root}/organization/storages/loading-points/list`;
				let params = new HttpParams();
				params = params.set("page", lpp.page.toString());
				params = params.set("size", lpp.size.toString());
				if (lpp.searchText)
					params = params.set("storageName", lpp.searchText);
				return this.http.get<LoadingPointsDto>(url, { params, withCredentials: true });
			}
		},
		get$: (storageId: number): Observable<Storage> => {
			const url = `${this.config.api.root}/organization/storages/${storageId}`;
			return this.http.get<Storage>(url, { withCredentials: true });
		},
		partners: {
			get$: (relatedPartyId: number, queryParams: FilterRequestParams): Observable<Storage> => {
				const url = `${this.config.api.root}/organization/partners/storages/filteredPage`;
				let params = new HttpParams();
				params = params.set("page", queryParams.page.toString());
				params = params.set("size", queryParams.size.toString());
				params = params.set("relatedPartyId", relatedPartyId.toString());

				if (queryParams.searchString) {
					params = params.set("searchString", queryParams.searchString.toString());
				}
				return this.http.get<Storage>(url, { params, withCredentials: true });
			}
		}
	};

	public rates_and_billing = {
		list: {
			get$: (): Observable<any> => {
				const url = `${this.config.api.root}/organization/tariff`;
				return this.http.get<ConsigneesDto>(url, { withCredentials: true });
			}
		}
	};

	public public_contract = {
		list: {
			get$: (filter: FilterRequestParams): Observable<any> => {
				const url = `${this.config.api.root}/organization/publicContract/list`;
				const params = new HttpParams()
					.set("page", filter.page.toString())
					.set("size", filter.size.toString());
				return this.http.get<PublicContractsList>(url, { params, withCredentials: true });
			}
		},
		accept: {
			post$: (code: string): Observable<void> => {
				const url = `${this.config.api.root}/organization/publicContract/${code}/accept`;
				return this.http.post<void>(url, null, { withCredentials: true });
			}
		}
	};

	public consignees = {
		list: {
			get$: (cp: ConsigneesParams, isVersion = true): Observable<ConsigneesDto> => {
				const url = `${this.config.api.root}/organization/consignees/list`;
				let params = new HttpParams();
				params = params.set("documentTypeId", cp.documentTypeId);
				params = params.set("page", cp.page.toString());
				params = params.set("size", cp.size.toString());

				if (cp.searchText) {
					params = params.set("consigneeName", cp.searchText);
				}

				if (typeof cp.isForeignPartner === "boolean" && isVersion) {
					params = params.set("isForeignPartner", cp.isForeignPartner);
				}

				if (typeof cp.internal === "boolean" && isVersion) {
					params = params.set("internal", cp.internal);
				}

				return this.http.get<ConsigneesDto>(url, { params, withCredentials: true });
			},

			unloadingPoints$: (upp: UnloadingPointsParams): Observable<UnloadingPointsDto> => {
				const url = `${this.config.api.root}/organization/consignees/${upp.consigneeId}/storages/unloading-points/list`;
				let params = new HttpParams();
				params = params.set("page", upp.page.toString());
				params = params.set("size", upp.size.toString());
				params = params.set("documentTypeId", upp.documentTypeId.toString());

				if (upp.searchText) {
					params = params.set("consigneeName", upp.searchText);
				}

				return this.http.get<UnloadingPointsDto>(url, { params, withCredentials: true });
			}
		}
	};

	public tariff = {
		plan: {
			current$: (): Observable<TariffPlanDto> => {
				const url = `${this.config.api.root}/tariff/plan/current`;
				return this.http.get<TariffPlanDto>(url, { withCredentials: true });
			},
			allPackage$: (planId: number): Observable<TariffPackageDto[]> => {
				const url = `${this.config.api.root}/tariff/plan/${planId}/package`;
				return this.http.get<TariffPackageDto[]>(url, { withCredentials: true });
			},
			status$: (planId: number, status: string): Observable<TariffPackageDto[]> => {
				const url = `${this.config.api.root}/tariff/plan/${planId}/package/${status}`;
				return this.http.get<TariffPackageDto[]>(url, { withCredentials: true });
			},
			archive$: (): Observable<ArchiveTariffPlanDto> => {
				const url = `${this.config.api.root}/tariff/plan`;
				return this.http.get<ArchiveTariffPlanDto>(url, { withCredentials: true });
			},
		},
		package: {
			function$: (packageId: number): Observable<TariffFunctionDto[]> => {
				const url = `${this.config.api.root}/tariff/package/${packageId}/function`;
				return this.http.get<TariffFunctionDto[]>(url, { withCredentials: true });
			}
		}
	}

	public providers = {
		list: {
			get$: (): Observable<PartyDto[]> => {
				const url = `${this.config.api.root}/organization/providers/list`;
				return this.http.get<PartyDto[]>(url, { withCredentials: true });
			}
		}
	};

	public createStorage = {
		create: {
			post$: (partyId: number, storageGln: string): Observable<StorageCreateDto> => {
				const url = `${this.config.api.root}/organization/consignees/${partyId}/storage`;
				let params = new HttpParams();
				params = params.set("storageGln", storageGln);
				return this.http.post<StorageCreateDto>(url, null, { params, withCredentials: true });
			}
		}
	};

	public checkGln = {
		check: {
			get$: (gln: string): Observable<PartyCheckDto> => {
				const url = `${this.config.api.root}/organization/checkGln`;
				let params = new HttpParams();
				params = params.set("gln", gln);
				return this.http.get<PartyCheckDto>(url, { params, withCredentials: true });
			}
		}
	};

	public updateGln = {
		update: {
			post$: (partyCheckDto: PartyCheckDto): Observable<PartyCheckDto> => {
				const url = `${this.config.api.root}/organization/updateGln`;
				const partySend = partyCheckDto;
				partySend.warningPresent && delete partySend.warningPresent;
				partySend.warningText && delete partySend.warningText;
				return this.http.post<PartyCheckDto>(url, partySend, { withCredentials: true });
			}
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }

	public get$(): Observable<IOrganization> {
		const url = `${this.config.api.root}/organization`;
		return this.http.get<OrganizationDto>(url, { withCredentials: true }).pipe(
			map(data => {
				data.dateUpdate = new Date(data.dateUpdate);
				return data;
			}));
	}

	public post$(data: IOrganization): Observable<void> {
		const url = `${this.config.api.root}/organization`;
		return this.http.post<void>(url, data, { withCredentials: true });
	}
}
