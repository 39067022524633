import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";

import { ConfigurationService } from "@core/configuration.service";
import { DocumentController } from "./document-controller";
import { DocumentForSignDto, RoseuBundleDto, RoseuDocumentDto, RoseuDocumentDtoUI, UpdDtoV2, UpdRspDtoV2 } from "@helper/abstraction/roseu";

export class Roseu extends DocumentController<any> {
	public get apiUrl(): string { 
		return `${this.config.api.roseu}`; 
	}

	public delete = {
		link: {
			delete$: (id: number[]): Observable<void> => {
				const url = `${this.apiUrl}/${id}`;
				return this.http.delete<void>(url, { body: id, withCredentials: true });
			}
		},
		document: {
			delete$: (id: number[], isRoot = false): Observable<void> => {
				const pathUrl = !isRoot ? "/document" : "";
				const url = `${this.apiUrl}${pathUrl}`;
				return this.http.delete<void>(url, { body: id, withCredentials: true });
			}
		}
	}

	// New api from roseu-form-controller
	public form = {
		getStatusDocument: {
			get$: (primary: RoseuDocumentDto): Observable<UpdRspDtoV2 | UpdDtoV2> => {
				const url = `${this.apiUrl}/${primary.formType}/${primary.formVersion}/${primary.id}`;
				return this.http.get<UpdRspDtoV2 | UpdDtoV2>(url, { withCredentials: true });
			}
		},
		saveSignResponseDocument: {
			post$: (data: { id: number, formType: string, formVersion: string, response?: RoseuDocumentDto | UpdRspDtoV2 }, base64?: boolean): Observable<DocumentForSignDto> => {
				const url = `${this.apiUrl}/${data.formType}/${data.formVersion}/${data.id}/response`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DocumentForSignDto>(url, data.response ?? {}, { withCredentials: true, params });
			}
		},
		updateResponseDocument: {
			put$: (document: RoseuBundleDto, response: UpdRspDtoV2): Observable<number> => {
				const url = `${this.apiUrl}/${document.primary.formType}/${document.primary.formVersion}/${document.primary?.id}/response`;
				return this.http.put<number>(url, response, { withCredentials: true });
			}
		},
		saveNoFormFile: {
			put$: (documentType: string, id: number, file: File): Observable<number> => {
				const url = `${this.apiUrl}/${documentType}/${id}`;
				const formData: FormData = new FormData();
				formData.append("file", file, file.name);
				return this.http.put<number>(url, formData, { withCredentials: true });
			}
		}
	}
	
	public downloadFile = {
		get$: (id: number): Observable<HttpResponse<Blob>> => {
			const url = `${this.apiUrl}/document/${id}/file`;
			return this.http.get<Blob>(url, {
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	// public getUpdDocument$(documentId: number): Observable<UpdDtoV2> {
	// 	const url = `${this.apiUrl}/UPD_PR/5.02/${documentId}`;
	// 	return this.http.get<UpdDtoV2>(url, { withCredentials: true });
	// }

	// public getUpdResponse$(documentId: number): Observable<UpdRspDtoV2> {
	// 	const url = `${this.apiUrl}/UPD_POK/5.02/${documentId}`;
	// 	return this.http.get<UpdRspDtoV2>(url, { withCredentials: true });
	// }

	public draft = {
		save: {
			post$: (draft: RoseuDocumentDto): Observable<RoseuDocumentDtoUI> => {
				const url = `${this.apiUrl}/document`;
				return this.http.post<RoseuDocumentDtoUI>(url, draft ?? {}, { withCredentials: true });
			}
		}
	}

	public processingTypes = {
		get$: (): Observable<any> => {
			const url = `${this.apiUrl}/types/list`;
			return this.http.get<any[]>(url, { withCredentials: true });
		}
	}

	constructor(
		private config: ConfigurationService,
		http: HttpClient
	) {
		super(http);
	}
}
